<template>
       <i18n-t :keypath="keyPath" :tag="tag" class="" :class="headerClass" scope="global">
            <template v-slot:action>
              <NuxtLink :to="'tel:' + globalStore.sitePrimaryPhoneNumber.country_code + globalStore.sitePrimaryPhoneNumber.call_to_action_number"
                class="whitespace-nowrap"
                :class='callClass'>
                {{
                  globalStore.sitePrimaryPhoneNumber.display_number
                }}
              </NuxtLink>
            </template>
            <template v-slot:postTitle>
              <p class="text-base leading-7 font-normal text-dark-blue">
                {{ $t("phoneCall.footer.postTitle") }}
              </p>
            </template>
          </i18n-t>
</template>

<style scoped></style>

<script setup>
import { useGlobalStore } from "@/stores/global";
const globalStore = useGlobalStore();
const props = defineProps({
  headerClass:{
    type: String,
    default: ''
  },
  callClass: {
    type: String,
    default: ''
  },
  keyPath:  {
    type: String,
    default: 'phoneCall.header.title'
  },
  tag: {
    type: String,
    default:'p'
  }
});


</script>
